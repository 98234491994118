import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import 'rxjs/add/operator/map';
import { Subscription } from 'rxjs/Subscription';

import { Category } from '../category';
import { ContentService } from '../content.service';
import { SiteSettingsService } from '../site-settings.service';

@Component({
    selector: 'app-alstjohn-content',
    templateUrl: 'content.component.html',
    styleUrls: [ 'content.component.css' ]
})
export class ContentComponent implements OnInit {
    subMenu: Category[];
    currentCategory: string;
    content: string;
    subscription: Subscription;
    parentCategory: Category;
    siteTitle: string;

    constructor(
        private siteSettingsService: SiteSettingsService,
        private route: ActivatedRoute,
        private router: Router,
        private contentService: ContentService
    ) {
        this.subscription = siteSettingsService.currentCategory$.subscribe(
            category => {
                if (this.parentCategory) {
                    this.currentCategory = this.parentCategory.title;
                } else {
                    this.currentCategory = category.title;
                }
            }
        );
        siteSettingsService.getSiteHeader()
            .then(h => this.siteTitle = h);
    }

    ngOnInit(): void {
        this.route.params
            .map(params => { return { category: params['category'], subcategory: params['subcategory']}; })
            .subscribe(params => {
                let pageCategory: string;
                if (params.subcategory) {
                    pageCategory = params.subcategory;
                    this.parentCategory = this.siteSettingsService.getCategoryById(params.category);
                } else if (params.category) {
                    pageCategory = params.category;
                }
                this.siteSettingsService.setCurrentCategory(pageCategory);
                this.siteSettingsService.getSubCategoryFor(pageCategory)
                    .then(subCategory => this.subMenu = subCategory);
                this.contentService.getPageContent(pageCategory)
                    .then(c => {
                        if (c) {
                            this.content = c;
                        }
                        else {
                            this.router.navigate(['/content/home']);
                        }
                    });
            });
    }
}
