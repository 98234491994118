import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';

import { Category } from '../category';
import { SiteSettingsService } from '../site-settings.service';

@Component({
    selector: 'app-alstjohn-submenu',
    templateUrl: 'submenu.component.html',
    styleUrls: [ 'submenu.component.css' ]
})
export class SubmenuComponent implements OnInit {
    @Input() menuItems: Category[];
    parentCategory: Category;
    currentCategory: string;
    subscription: Subscription;

    constructor(
        private siteSettingsService: SiteSettingsService,
        private route: ActivatedRoute
    ) {
        this.subscription = siteSettingsService.currentCategory$.subscribe(
            category => {
                this.currentCategory = category.title;
                this.siteSettingsService.findParentCategoryById(this.currentCategory)
                    .then(c => this.parentCategory = c);
            }
        );
    }

    ngOnInit(): void {
        this.route.params
            .subscribe(params => {
                if (params['subcategory']) {
                    this.parentCategory = this.siteSettingsService.getCategoryById(params['category']);
                }
            });
    }
}
