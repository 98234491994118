<div id="container" (click)="containerGotFocus()" (mouseover)="containerGotFocus()">
  <div class="swipe-box"
      *ngFor="let galleryImage of galleryImages; let idx=index"
      (swipeleft)="swipe($event.type)"
      (swiperight)="swipe($event.type)"
      [class.visible]="galleryImage.visible"
      [class.hidden]="!galleryImage.visible">
      <figure>
        <img [src]="galleryImage.path" [alt]="galleryImage.caption" />
        <figcaption>{{galleryImage.caption}}</figcaption>
      </figure>
  </div>

  <button routerLink="/content/archive/gallery" id="closebutton" [style.visibility]="buttonVisibility" class="imagebutton"><i class="fa fa-times fa-4x"></i></button>
  <button (click)="previousImage()" id="leftbutton" [style.visibility]="buttonVisibility" class="imageselectorbutton imagebutton"><i class="fa fa-angle-left fa-4x" aria-hidden="true"></i></button>
  <button (click)="nextImage()" id="rightbutton" [style.visibility]="buttonVisibility" class="imageselectorbutton imagebutton"><i class="fa fa-angle-right fa-4x" aria-hidden="true"></i></button>
</div>
