import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ContentComponent } from './content/content.component';
import { SplashComponent } from './splash/splash.component';
import { ThumbnailGalleryComponent } from './thumbnail-gallery/thumbnail-gallery.component';
import { FullScreenGalleryComponent } from './full-screen-gallery/full-screen-gallery.component';

const routes: Routes = [
    { path: '', component: SplashComponent },
    { path: 'content/archive/gallery', component: ThumbnailGalleryComponent },
    { path: 'content/fullscreengallery/:imageidx', component: FullScreenGalleryComponent },
    { path: 'content/:category', component: ContentComponent },
    { path: 'content/:category/:subcategory', component: ContentComponent },
    { path: '**', redirectTo: '/content/home', pathMatch: 'full' }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}
