import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { Category } from '../category';
import { SiteSettingsService } from '../site-settings.service';

@Component({
    selector: 'app-alstjohn-mainmenu',
    templateUrl: 'mainmenu.component.html',
    styleUrls: ['mainmenu.component.css']
})
export class MainmenuComponent implements OnInit {
    @Output() menuItemSelected = new EventEmitter();
    mainMenu: Category[];

    constructor(private siteSettingsService: SiteSettingsService) {}

    hide(): void {
        this.menuItemSelected.emit();
    }

    ngOnInit() {
        this.getMainMenu();
    }

    getMainMenu(): void {
        this.siteSettingsService.getMainMenuItems().then(items => this.mainMenu = items);
    }
}
