<div id="main">
    <div id="mobile-menu" [@mobileMenuAnimation]="menuState">
        <a *ngFor="let item of mobileMenu"
            routerLink="{{item.routerLink}}"
            class="mobile-menu-item"
            (click)="toggleMenu()">
            <h1 [@mobileMenuTextAnimation]="menuState">{{item.title}}</h1>
        </a>
    </div>
    <div id="header">
        <h1 id="top-header"><a routerLink="/">{{siteHeader}}</a></h1>
        <button *ngIf="!showMenu"
                id="open-menu-button"
                class="menu-button"
                (click)="toggleMenu()"></button>
        <button *ngIf="showMenu"
                id="close-menu-button"
                class="menu-button"
                (click)="toggleMenu()"></button>
    </div>

    <div id="main-content">
        <router-outlet></router-outlet>
    </div>

    <app-alstjohn-footer></app-alstjohn-footer>
</div>