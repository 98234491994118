<div id="category-header-container">
    <h2 id="category-header">{{currentCategory}}</h2>
</div>

<div id="sidebar">
    <app-alstjohn-sidebar></app-alstjohn-sidebar>
</div>
<!--
<div id="subheader-container">
    
    <a *ngIf="parentCategory" [routerLink]="parentCategory.routerLink" id="back-button"><h2>&lt;</h2></a>
    <h2 id="subheader">{{currentCategory}}</h2>
</div>-->

<app-alstjohn-submenu [menuItems]="subMenu"></app-alstjohn-submenu>

<div id="content" role="main">
  <div class="thumbnailContainer" *ngFor="let galleryImage of galleryImages">
    <a routerLink="{{'/content/fullscreengallery/' + galleryImage.index}}"><img [src]="galleryImage.thumbPath" /></a>
  </div>
</div>
