import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Category } from './category';

export const categories: Category[] = [
            { id: 'home', title: 'Home', routerLink: '/content/home', subMenu: [] },
            { id: 'news', title: 'News', routerLink: '/content/news', subMenu: [] },
            { id: 'about', title: 'About', routerLink: '/content/about', subMenu: [
                { id: 'alstjohn', title: 'Al St.John', routerLink: '/content/about/alstjohn', subMenu: [] },
                { id: 'theproject', title: 'The project', routerLink: '/content/about/theproject', subMenu: [] },
                { id: 'thebiography', title: 'The biography', routerLink: '/content/about/thebiography', subMenu: [] },
                { id: 'filmreleases', title: 'Film releases', routerLink: '/content/about/filmreleases', subMenu: [] },
                { id: 'faq', title: 'FAQ', routerLink: '/content/about/faq', subMenu: [] }
            ] },
            { id: 'career', title: 'Career', routerLink: '/content/career', subMenu: [
                { id: 'theatrevaudeville', title: 'Theatre/Vaudeville', routerLink: '/content/career/theatrevaudeville', subMenu: [] },
                { id: 'filmography', title: 'Filmography', routerLink: '/content/career/filmography', subMenu: [] },
                { id: 'westernshows', title: 'Western Shows', routerLink: '/content/career/westernshows', subMenu: [] }
            ] },
            { id: 'archive', title: 'Archive', routerLink: '/content/archive', subMenu: [
                { id: 'gallery', title: 'Gallery', routerLink: '/content/archive/gallery', subMenu: [] }
            ] },
            { id: 'links', title: 'Links', routerLink: '/content/links', subMenu: [] }
        ];

@Injectable()
export class SiteSettingsService {
    private currentCategorySource = new Subject<Category>();

    currentCategory$ = this.currentCategorySource.asObservable();

    getSiteHeader(): Promise<string> {
        return Promise.resolve('Al St.John Official');
    }

    setCurrentCategory(categoryTitle: string) {
        let category = this.getCategoryById(categoryTitle);
        if (category !== null) {
            this.currentCategorySource.next(category);
        }
    }

    findParentCategoryById(id: string): Promise<Category> {
        for (let item of categories) {
            for (let subitem of item.subMenu) {
                if (subitem.title.toLowerCase() === id.toLowerCase()) {
                    return Promise.resolve(item);
                }
            }
        }

        return Promise.resolve(undefined);
    }

    getCategoryById(id: string): Category {
        return this._getCategoryById(categories.slice(), id);
    }

    private _getCategoryById(categoriesToSearch: Category[], id: string): Category {
        if (categoriesToSearch.length) {
            let category = categoriesToSearch.shift();
            if (category.id.toLowerCase() === id.toLowerCase()) {
                return category;
            }
            if (category.subMenu.length) {
                categoriesToSearch = categoriesToSearch.concat(category.subMenu);
            }
            return this._getCategoryById(categoriesToSearch, id);
        }

        return { id: '', title: '', routerLink: '/', subMenu: [] };
    }

    getMobileMainMenuItems(): Promise<Category[]> {
        return Promise.resolve(categories.filter(value => value.title !== 'Links'));
    }

    getMainMenuItems(): Promise<Category[]> {
        return Promise.resolve(categories);
    }

    getSubCategoryFor(categoryId: string): Promise<Category[]> {
        for (let item of categories) {
            if (item.id.toLowerCase() === categoryId.toLowerCase()) {
                return Promise.resolve(item.subMenu);
            }
        }
        return Promise.resolve([]);
    }
}
