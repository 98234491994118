<div id="link-footer">
    <div id="footer-icons-left">
        <a href="mailto:contact@alstjohn.net" id="mail-icon" class="footer-icon">
            <!--<img id="mail-icon" src="assets/images/icon-mail.gif" />-->
        </a>
    </div>
    <div id="footer-icons-right">
        <a *ngFor="let link of socialLinks"
            [href]="link.getLinkUrl()"
            class="footer-icon"
            [ngStyle]="{'background-image': 'url(' + link.getIconUrl() + ')'}">
        </a>
    </div>
</div>
<div id="copyright-text" [innerHTML]="copyrightText"></div>
<div id="menu-container">
    <nav id="footer-menu">
        <ul>
            <li *ngFor="let menuItem of menuItems">
                <a routerLink="{{menuItem.routerLink}}"
                (click)="hide()">{{menuItem.title}}</a>
            </li>
        </ul>
    </nav>
</div>
<div id="signature">
    <img src="/assets/images/signature.png" />
</div>
