import { Component, OnInit, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ContentService } from '../content.service';
import { GalleryImage } from '../gallery-image';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pinch': { enable: true }
  };
}

@Component({
  selector: 'app-full-screen-gallery',
  templateUrl: './full-screen-gallery.component.html',
  styleUrls: [
    './full-screen-gallery.component.css'
  ],
  providers: [{
    provide: HAMMER_GESTURE_CONFIG,
    useClass: HammerConfig
  }]
})
export class FullScreenGalleryComponent implements OnInit {

  currentIndex: number = 0;
  galleryImages: GalleryImage[];
  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };
  showButtonsTimeoutId: NodeJS.Timer;
  buttonVisibility: string = 'hidden';

  constructor(
    private contentService: ContentService,
    private route: ActivatedRoute) { }

  swipe(action = this.SWIPE_ACTION.RIGHT) {
    if (this.currentIndex > this.galleryImages.length || this.currentIndex < 0) {
      return;
    }

    let nextIndex = 0;

    this.buttonVisibility = 'hidden';

    if (action === this.SWIPE_ACTION.LEFT) {
      const isLast = this.currentIndex === this.galleryImages.length - 1;
      nextIndex = isLast ? 0 : this.currentIndex + 1;
    }

    if (action === this.SWIPE_ACTION.RIGHT) {
      const isFirst = this.currentIndex === 0;
      nextIndex = isFirst ? this.galleryImages.length - 1 : this.currentIndex - 1;
    }

    console.log('Showing image ' + nextIndex);
    this.showImage(nextIndex);
  }

  nextImage() {
    const isLast = this.currentIndex === this.galleryImages.length - 1;
    this.showImage(isLast ? 0 : this.currentIndex + 1);
  }

  previousImage() {
    const isFirst = this.currentIndex === 0;
    this.showImage(isFirst ? this.galleryImages.length - 1 : this.currentIndex - 1);
  }

  showImage(imageIdx: number) {
    this.currentIndex = imageIdx;
    this.galleryImages.forEach((x, i) => x.visible = (i === this.currentIndex));
  }

  containerGotFocus() {
    clearTimeout(this.showButtonsTimeoutId);
    this.buttonVisibility = 'visible';
    this.showButtonsTimeoutId = setTimeout(() => this.buttonVisibility = 'hidden', 5000);
  }

  ngOnInit() {
    this.route.params
            .map(params => { return { imageIdx: params['imageidx'] }; })
            .subscribe(params => {
              this.contentService.getGalleryImages()
                .then(gi => {
                  this.galleryImages = gi;

                  let index = params.imageIdx * 1;
                  if (index > 0 && index < this.galleryImages.length) {
                    this.showImage(index);
                  }
                });
            });
  }
}
