import { Injectable } from '@angular/core';

import { GalleryImage } from './gallery-image';

@Injectable()
export class ContentService {

    private galleryImages: GalleryImage[] = [
      {
        index: 0,
        path: 'assets/images/gallery/asj_net_1.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_1.gif',
        caption: 'Portrait from The Dance of Life (1929) Al St. John as "Bozo".',
        visible: true
      },
      {
        index: 1,
        path: 'assets/images/gallery/asj_net_2.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_2.gif',
        caption: 'Movie Still from All Sealed Up (1931).',
        visible: false
      },
      {
        index: 2,
        path: 'assets/images/gallery/asj_net_3.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_3.gif',
        caption: 'Arcade Card from ca. 1925.',
        visible: false
      },
      {
        index: 3,
        path: 'assets/images/gallery/asj_net_4.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_4.gif',
        caption: 'French Cinema Program from 1923.',
        visible: false
      },
      {
        index: 4,
        path: 'assets/images/gallery/asj_net_5.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_5.gif',
        caption: 'Arcade Card from ca. 1923.',
        visible: false
      },
      {
        index: 5,
        path: 'assets/images/gallery/asj_net_6.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_6.gif',
        caption: 'Signed photo of Al St. John as "Fuzzy" and Lash Larue.',
        visible: false
      },
      {
        index: 6,
        path: 'assets/images/gallery/asj_net_7.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_7.gif',
        caption: 'Movie Still from Buzzin\' Around (1933).',
        visible: false
      },
      {
        index: 7,
        path: 'assets/images/gallery/asj_net_8.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_8.gif',
        caption: 'Movie Still from Ain\'t Love Grand ? (1921).',
        visible: false
      },
      {
        index: 8,
        path: 'assets/images/gallery/asj_net_9.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_9.gif',
        caption: 'Movie Still from Wild Horse Phantom (1944).',
        visible: false
      },
      {
        index: 9,
        path: 'assets/images/gallery/asj_net_10.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_10.gif',
        caption: 'Movie Still from Gangster\'s Den (1945)',
        visible: false
      },
      {
        index: 10,
        path: 'assets/images/gallery/asj_net_11.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_11.gif',
        caption: 'Movie Still from Shadows of Death (1945).',
        visible: false
      },
      {
        index: 11,
        path: 'assets/images/gallery/asj_net_12.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_12.gif',
        caption: 'Movie Still from Ghost of Hidden Valley (1946).',
        visible: false
      },
      {
        index: 12,
        path: 'assets/images/gallery/asj_net_13.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_13.gif',
        caption: 'Movie Still from Billy The Kid\'s Fighting Pals (1941)',
        visible: false
      },
      {
        index: 13,
        path: 'assets/images/gallery/asj_net_14.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_14.gif',
        caption: 'Movie Still from Billy the Kid in Texas (1940)',
        visible: false
      },
      {
        index: 14,
        path: 'assets/images/gallery/asj_net_15.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_15.gif',
        caption: 'Movie Still from The Happy Pest (1921).',
        visible: false
      },
      {
        index: 15,
        path: 'assets/images/gallery/asj_net_16.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_16.gif',
        caption: 'Glass Slide from The Hayseed (1921).',
        visible: false
      },
      {
        index: 16,
        path: 'assets/images/gallery/asj_net_17.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_17.gif',
        caption: 'Al St. John Jigsaw Puzzle from ca. 1926.',
        visible: false
      },
      {
        index: 17,
        path: 'assets/images/gallery/asj_net_18.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_18.gif',
        caption: 'Lobby Card from Fire Away (1925).',
        visible: false
      },
      {
        index: 18,
        path: 'assets/images/gallery/asj_net_19.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_19.gif',
        caption: 'Lobby Card from Fair Warning (1925).',
        visible: false
      },
      {
        index: 19,
        path: 'assets/images/gallery/asj_net_20.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_20.gif',
        caption: 'Lobby Card from Live Cowards (1926).',
        visible: false
      },
      {
        index: 20,
        path: 'assets/images/gallery/asj_net_21.jpg',
        thumbPath: 'assets/images/gallery/thumbs/asj_net_21.gif',
        caption: 'Lobby Card from Live Cowards (1926).',
        visible: false
      }
    ];

    private content: { [category: string]: string } = {
        'about': `
          <p>
            Al St. John is today mostly remembered as «second banana» to his uncle Roscoe «Fatty» Arbuckle in the Comique series and as the
            cult figure Western sidekick «Fuzzy Q Jones». His actual history is mostly forgotten today and what is remembered is often
            wrong. His talent is greatly underrated as most of St. John’s big productions are as of today lost. Still he was in a league of
            his own with a flair for surreal physical comedy and continuation to reinvent himself all through his career. Throughout his
            lifetime he worked in most branches of the entertainment industry - from circus and street artist to theatre, vaudeville and
            the movies, before he ended the journey as a performer at the traveling western revues.
          </p>
          <img src="assets/images/content/promoflyer.jpg" />
        `,
        'alstjohn': `
            <table>
              <tr>
                <td>Name:</td>
                <td>Alfred St. John</td>
              </tr>
              <tr>
                <td>Date of birth:</td>
                <td>September 10 1892 - Santa Ana</td>
              </tr>
              <tr>
                <td>Date of death:</td>
                <td>January 21 1963 - Georgia</td>
              </tr>
              <tr>
                <td>Height:</td>
                <td/>5’8’’  / 173 cm</td>
              </tr>
                <td>Weight:</td>
                <td>140 lbs  /  64 kg</td>
              </tr>
              <tr>
                <td>Hair:</td>
                <td>Sandy blonde</td>
              </tr>
              <tr>
                <td>Eyes:</td>
                <td>Light blue<td>
              </tr>
            </table>
            <figure>
              <img src="assets/images/content/about.jpg" />
              <figcaption>This portrait was inside Roscoe «Fatty» Arbuckle’s personal scrapbook that was compiled by Arbuckle and later on,
              after his death, kept by Minta Durfee, his wife, all her life. Today this original vintage portrait is part of The Al St.
              John Archive.</figcaption>
            </figure>
            <h3>Other</h3>
            <p>
              Trademark in early comedies was his missing teeth on upper left side.
              Later on Al wore dentures for a more subtle look to suit the comedy style of the 1920s.
              In the 40s Westerns became immensely popular and Al created the famous Fuzzy Q Jones - once again he could take advantage of
              his appearance to amplify the character.
            </p>
            <h3>Family</h3>
            <ul>
              <li>Walter St. John - Father</li>
              <li>Nora N Arbuckle - Mother</li>
              <li>Marion Lillian Ball - Spouse 1914 - 1923</li>
              <li>Mary Jane St. John - Adopted daughter</li>
              <li>June Price Pearce - Spouse 1926 until her death</li>
              <li>Florence Belle Moore - Spouse until his death</li>
            </ul>
            <h3>Biography</h3>
            <p>
              At the present Annichen Skaren is researching St. John for a definitive biography about his life and work. The book will be
              released as soon as there is a certainty that most of the information have been retrieved, and the story seems consistent
              with all the historical information that have been found in various archives and other sources.
              The best way to learn about Al St. John until the biography have been released is to visit the Official website and social
              media pages, and support The Project by purchasing the film collection.
            </p>
            <h3>Website</h3>
            <p>
              This is a temporary website.
              When the biography and film collection is released everything from the physical archive will be scanned and photographed
              especially for the website to create a digital online Al St. John Archive.
            </p>
        `,
        'archive': `
          <p>
            The Al St. John Archive is an extensive collection of rare movie memorabilia, film prints and personally owned items of St.
            John, his uncle Roscoe «Fatty» Arbuckle, family members, several of Al’s managers and co-workers. Everything will be made
            available for fans; either online, or at live screenings and exhibitions. Visit the
            <a href="/content/archive/gallery/">gallery</a>.
          </p>

          <figure>
            <img src="assets/images/content/archive.jpg" />
            <figcaption>A small part of The Al St. John Archive.</figcaption>
          </figure>
        `,
        'career': `
          <p>
            St. John worked in vaudeville and theatre prior to joining the Keystone Film Company in 1911. After St. John left the movies in
            1952, he went on tour with various western shows until his death in 1963.
          </p>

          <figure>
            <img src="assets/images/content/career.jpg" />
            <figcaption>Magazine Ad for The Aero-Nut (1920)</figcaption>
          </figure>
        `,
        'faq': `
          <h4>
            When will the biography be ready ?
          </h4>

          <p>
            Until now, the life and work of St. John have been neglected - his legacy have been lost and forgotten.<br />
            Material about St. John is extremely hard to find as everyone who knew him are long gone and nobody ever interviewed him or
            people who knew him personally. For this reason the research process for the biography is time consuming and can not be hurried
            along. Still already there is enough material to cover the story of his life and career - what is missing is mainly small
            details that surely is out there waiting to be found. The aim is to write a definitive biography and it would be a shame to
            miss important details just to have it released quickly.
          </p>

          <h4>
            When will the films be released ?
          </h4>

          <p>
            The film collection will be released as sets in various formats .
            At the present time, about 30 films have been found and transferred from film to high resolution digital format.
            The digital restoration started in 2014, and the releases can be expected when the films have been restored to their best
            possible end result, taking the source material and time that is at our disposal into consideration.
            The films are cleaned up frame by frame — Lines, dust, scratches and various damage is removed, while jitter and color is
            corrected.
          </p>

          <p>
            To stay updated follow Al St. John Official on <a href="https://www.facebook.com/AlStJohnOfficial/">Facebook</a> and visit the
            <a href="/content/news">news section</a>.
          </p>

          <h4>
            Can I post images and information from the website on my page ?
          </h4>

          <p>
            Everything posted on The Official Al St. John Website is from The Al St. John Archive. All images have a watermark in order to
            promote Al St. John and make it easier to educate fans about his life and work.
            If you want to help, it would be better to write your own thoughts and link to the Al St. John website and other Al St. John
            social media pages.
          </p>

          <h4>
            I have a website - Can we exchange links ?
          </h4>

          <p>
            If you have an affiliated website we’ll be happy to exchange links.
          </p>

          <h4>
            Is the website run by Al St. John’s relatives ?
          </h4>

          <p>
            The website is run by The Estate of Al St. John - Annichen Skaren is the Official representative of St. John and his legacy.
            His relatives have approved the website as Official and appointed Skaren as Official representative of The Al St. John Estate
            and legacy.
          </p>

          <h4>
            Where can I buy Al St. John merchandise ?
          </h4>

          <p>
            There will be a line of merchandise when the films are released. Until then visit the
            <a href="http://www.zazzle.com/alstjohn">Official Zazzle shop</a> - All proceeds go to the
            restoration and release of Al St. John's movies.
          </p>

          <h4>
            I have some Al St. John photos / films / posters - would you be interested in buying / using it for The Project ?
          </h4>

          <p>
            Absolutely. Part of the project is to track down movie memorabilia and personally owned items - anything related to Al St. John
            is of great interest and help.
            All material will be used for the biography, film releases, exhibitions, screenings etc.
            Eventually this website will be turned into an online archive for everyone to enjoy and use for further historical research if
            wanted.
          </p>

          <p>
            Send enquiries to : <a href="mailto:contact@alstjohn.net">contact@alstjohn.net</a>
          </p>

          <h4>
            Who runs The Al St. John Project ?
          </h4>

          <p>
            The project was founded by Official representative of The Estate of Al St. John - Film restorer, historian, archivist and
            leading authority on Al St. John, Annichen Skaren, in 2012.
            www.annichenskaren.com
          </p>
        `,
        'filmreleases': `
          <p>
            The film collection will be released as sets for as long as there is material at hand.
            At the present time, about 30 films have been found and transferred from film to high resolution digital format.
            The digital restoration started in 2014, and the releases can be expected when the films have been restored to their best
            possible end result, taking the source material and time that is at our disposal into consideration. The films are cleaned up
            frame by frame — Lines, dust, scratches and various damage is removed, while jitter and color is corrected.
          </p>

          <figure>
            <img src="assets/images/content/film_releases.jpg" />
            <figcaption>Listen Lena (1927) Press Sheet.</figcaption>
          </figure>
        `,
        'filmography': `
          <p>
            When Al started working for the Keystone Film Company in 1911 he mainly did stunts and extra work. He can often be spotted in
            the background of those early shorts.
            It is impossible to determine exactly how many of those shorts he worked on, as Keystone often used their regular actors for
            extra parts.
            Al’s Keystone filmography will never be 100% accurate.
            The filmography will always be subject to change as the research continues.
          </p>

          <p>
            A Filmography page will eventually be created for the website, including updates on the survival of the various films and where
            they can be found and/or purchased. This can be expected around the time the film collection is released.
          </p>

          <figure>
            <img src="assets/images/content/filmography.jpg" />
            <figcaption>Movie Still from Out West (1918)</figcaption>
          </figure>
        `,
        'gallery': this._getContentForGallery(),
        'home': `
          <p>
            Welcome to the Official Al St. John website, the place to be for all fans of «The bounding boy of the screen». This page is run
            by the Estate of Al St. John - Here you can find information about Al as well as news, photos and all the latest about the film
            restoration and biography. The website will eventually turn into a online archive of all things St. John so if you want to keep
            updated you have certainly come to the right place. Look around and enjoy !
          </p>

          <p>
            Yours for fun,<br />
            The Estate of Al St. John.
          </p>

          <img src="assets/images/content/home.jpg" />
        `,
        'links': `
          <ul>
            <li><a href="https://classichollywood.wordpress.com/">Classic Hollywood</a></li>
            <li><a href="http://www.stjohngenealogy.com/">St.John Genealogy</a></li>
            <li><a href="http://www.silentfilmstillarchive.com/">The Silent Film Still Archive</a></li>
            <li><a href="https://buckeyg2.wordpress.com/">Pre-Views - The Motion Picture Cameramen in the Silent Era</a></li>
            <li><a href="https://en.wikipedia.org/wiki/Al_St._John_filmography">Al St.John filmography on Wikipedia</a></li>
          </ul>
        `,
        'news': `
          <h4>7th March, 2018</h4>
          <h3>Al St. John @ Silent Laughter, London -  March 11th</h3>
          <img src="assets/images/content/al_st_john_sky_bound_1926.jpg" />
          <p>
            If you happen to be in London Sunday March 11th make sure to visit The Cinema Museum.
            Al St. John makes an appearance in SKY BOUND (1926) and Annichen Skaren will share a few exclusive stories that haven’t been
            heard in a very long time accompanied by some rare images and screenshots from unreleased Al St. John silent comedies.
          </p>
          <img src="assets/images/content/annichen_skaren_the_cinema_museum_london.jpg" />
          <p>
            <a href="https://thelostlaugh.com/2018/02/07/silent-laughter-2018-programme-revealed/">Silent laughter programme</a>
          </p>

          <hr />

          <h4>28th October, 2017</h4>
          <h3>The Al St. John YouTube channel</h3>
          <p>
            Back in 2012 the Al St. John Youtube channel was started as a way of promoting the talents of a very underrated artist with the
            hopes that his unseen films would be released on DVD. Now, when there finally is a Al St. John film collection in production,
            it is time to say goodbye to inferior quality videos with random music and hello to high quality films with custom made scores.
          </p>
          <p>
            But first let’s explain a bit about how the industry works. Free video websites like Youtube is a great threat to indie
            distributors of silent and classic cinema. Those who do the tedious work to bring these films out of archives as well as spend
            a lot of time and money on their projects are constantly pirated by fans who unknowingly think they are doing a good thing by
            uploading the films for free online.
          </p>
          <p>
            Unfortunately this means that each silent film uploaded to Youtube or similar websites means less of a chance for these movies
            to get released in good quality with professional scores.
            It is not a profitable business to release silent and classic films, it is a labor of love. This is a subject of great
            importance and the word needs to get around to new fans as well as fans that are unaware of the great damage they are doing to
            a very small «industry». Independent silent and classic film distributors are few and the reason for this is simply that it is
            a tough and non-lucrative job - The reward being nothing more than the privilege to be the one who get to spend countless hours
            and dollars bringing those long forgotten gems back to the audience. Not a lot of people are made of the stuff it takes to get
            so little in return while giving so much.
          </p>
          <p>
            Next time you enjoy a movie on Youtube, take the time to think about these words. Support your classic film distributors and
            buy their releases rather than watch them on Youtube. Make sure that even more films are released from their archival prison
            and the death sentence it actually is when fans refuse to pay for what they love. Many films will never find their way to your
            screen if the individuals who work so hard to give you the chance to watch these films get pirated every time they release a
            film. For the sake of the future film collection the Al St. John Youtube channel will from now on be used for promotion only.
          </p>
          <img src="assets/images/content/news_youtube_channel.jpg" />

          <hr />

          <h4>28th October, 2017<h4>
          <h3>E-mail issues</h3>
          <p>
            We had some techincal issues while switching to a new e-mail provider. If you tried to contact us on e-mail during the last
            couple of days and didn't get an answer (or got a delivery failure message), try resending your e-mail.
          </p>
          <img src="assets/images/content/email_issues.jpg" />

          <hr />

          <h4>26th March, 2017</h4>
          <h3>Looking to hire Silent Film musicians</h3>
          <p>
            Do you want to work with The Al St. John Project ? If you are interested please send a
            <a href="mailto:contact@alstjohn.net">message</a> with the following details:

            <ul>
              <li>Send link to previous work.</li>
              <li>How long do you usually need to complete a two reel score?</li>
              <li>Do you have time to work this year?</li>
            </ul>

          </p>
          <img src="assets/images/content/exhibitorsherald.jpg" />

          <hr />

          <h4>19th March, 2017</h4>
          <h3>Official Al St. John website and representative of his Legacy.</h3>

          <p>
            Over 5 years working on The Al St. John Project the results are beginning to show, and after several years of friendship with
            the St. John relatives they have appointed me Official representative of The Estate of Al St. John. This is a big honor for me
            and not something I take lightly - the task to retrieve the lost films and history of Al St. John have been a long and hard
            journey. This is a true labor of love.
          </p>
          <p>
            I am proud to finally bring you The Official Al St. John Website, long overdue, but now it is here and from now on the only way
            is up - back up to the pedestal in history that St. John worked so hard for.
            It is time for everyone to get to know the real artist and his work, and I promise you, it is all on the way!
          </p>
          <p>
            The film collection is in its final stage of restoration and will hit the market as soon as possible.
          </p>
          <img src="assets/images/content/news_film_collection.jpg"/>
          <p>
            The biography is going incredibly well, despite that it had everything going against it - everyone who knew Al have passed away
            long ago, no historian ever sat down and talked to him, he himself didn’t try to preserve his legacy and neither did any of his
            immediate family - and there weren’t many of them in the first place.
            With a lot of hard work, long hours and deep pockets I have managed to find the long lost story of one of film history’s most
            exceptional performers - a stunt and acrobatics pioneer, a versatile and hard working, multi talented artist, who worked his
            entire life to make people laugh - There really is no finer art.
          </p>
          <img src="assets/images/content/news_biography.jpg"/>
          <p>
            This website is for now merely a basic site but will eventually be home to The Al St. John Digital Archive.
            When the biography and film collection is released, the entire physical archive will be scanned and photographed, especially
            for the website, and made available for everyone to enjoy.
          </p>
          <img src="assets/images/content/news_archive.jpg" />
          <p>
            Please welcome back : www.alstjohn.net
          </p>
          <p>
            With special thanks to the St.John relatives.
          </p>
          <p>
            Sincerely,<br/>
            Annichen Skaren - Official representative of The Estate of Al St. John.
          </p>
          <img src="assets/images/content/website_news.jpg" />
        `,
        'thebiography': `
          <p>
            The biography has been in progress since 2012 based on the research performed by leading authority on Al St. John, Annichen
            Skaren.
            At the present time there is enough material for a detailed biography on St. John, both the private person and artist.
            Taking into consideration that there are many unexplored resources left, the research will continue until there is a certainty
            that most of the information have been retrieved, and the story seems consistent.
            As many have wrongly portrayed St. John in the past, resulting in the loss of films and persona, the project strives to deliver
            a biography that can right the wrongs of the past, and further secure Al St. John’s place in history as the pioneer he was.
          </p>
          <figure>
            <img src="assets/images/content/biography.jpg" />
            <figcaption>This photo of Al St. John together with Buster Keaton, Roscoe «Fatty» Arbuckle, Alice Lake and Luke the Dog was
            originally inside Arbuckle’s personal scrapbook. Today this original vintage photo is part of The Al St. John
            Archive.</figcaption>
          </figure>
        `,
        'theproject': `
          <p>
            The project was launched in 2012 by Official representative of The Estate of Al St. John - Film restorer, historian, archivist
            and leading authority on Al St.John, Annichen Skaren.
            The goal is to retrieve the lost films of Al St. John in order to restore and preserve them.
            Also part of the project is to correct the history of St. John and fill in the gaps caused by misinformation and lack of
            research in the past.
            The complete works will be presented as a biography, a film collection and the online archive.
            There will also be screenings at various locations - please visit the news sections to keep updated.
          </p>

          <figure>
            <img src="assets/images/content/annichen_at_film_lab_2013.jpg" />
            <figcaption>Annichen Skaren at the film lab in 2013.</figcaption>
          </figure>
        `,
        'theatrevaudeville': `
          <p>
            Prior to working in the movies, Al had gained stage experience mainly through working with his aunt Minta and uncle Roscoe
            Arbuckle.
            He did a few tours as well as various personal appearances throughout his career.
            The full story can be read in the Al St. John biography that is in progress.
          </p>

          <figure>
            <img src="assets/images/content/theatre_vaudeville.jpg" />
            <figcaption>The Keystone Cops on Tour.</figcaption>
          </figure>
        `,
        'westernshows': `
          <p>
            In the 1940s Al created the world famous Fuzzy Q. Jones for the Westerns.
            These movies were highly popular and Al once again became a household name all around the world. Between filming, the stars
            went on the road to perform live for their audience.
            Western shows attracted fans of all ages, especially kids who frequented the movie theatre matinees, visited the Western shows
            to meet their cowboy heroes - and perhaps even take a photo and pick up a signed portrait.
          </p>

          <p>
            Today the Fuzzy character has become a cult icon, especially in Germany, where the films are released as Fuzzy box sets rather
            than under their original titles. He has also become the star while the previous stars of those westerns have taken a back seat.
          </p>

          <figure>
            <img src="assets/images/content/western_shows.jpg" />
            <figcaption>Original poster for Al St. John’s Western Revue Tour.</figcaption>
          </figure>
        `,
    };

    getPageContent(category: string): Promise<string> {
        let preparedString = category.split(' ').join('-');
        return Promise.resolve(this.content[preparedString]);
    }

    getGalleryImages(): Promise<GalleryImage[]> {
      return Promise.resolve(this.galleryImages);
    }

    private _getContentForGallery(): string {
        let stringBuilder = [];

        /*for (let i = 1; i < 22; ++i) {
            stringBuilder.push('<img src="assets/images/gallery/asj_net_');
            stringBuilder.push('' + i);
            stringBuilder.push('.jpg" />');
        }*/

        for (let image of this.galleryImages) {
            stringBuilder.push('<a routerLink="/content/fullscreengallery/' + image.index + '">');
            stringBuilder.push('<div class="thumbnailContainer">');
            stringBuilder.push('<img src="');
            stringBuilder.push(image.thumbPath);
            stringBuilder.push('" />');
            stringBuilder.push('</div>');
            stringBuilder.push('</a>\n');
        }

        return stringBuilder.join('');
    }
}
