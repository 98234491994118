import { Component, OnInit } from '@angular/core';

import { SiteSettingsService } from '../site-settings.service';

@Component({
    selector: 'app-alstjohn-sidebar',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    siteTitle: string;

    constructor(private siteSettingsService: SiteSettingsService) {}

    ngOnInit(): void {
        this.getSiteTitle();
    }

    getSiteTitle(): void {
        this.siteSettingsService.getSiteHeader().then(title => this.siteTitle = title);
    }
}
