import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { ContentComponent } from './content/content.component';
import { FooterComponent } from './footer/footer.component';
import { MainmenuComponent } from './mainmenu/mainmenu.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SubmenuComponent } from './submenu/submenu.component';

import { ContentService } from './content.service';
import { SiteSettingsService } from './site-settings.service';

import { AppRoutingModule } from './app-routing.module';
import { FullScreenGalleryComponent } from './full-screen-gallery/full-screen-gallery.component';
import { ThumbnailGalleryComponent } from './thumbnail-gallery/thumbnail-gallery.component';
import { SplashComponent } from './splash/splash.component';

@NgModule({
  declarations: [
    AppComponent,
    ContentComponent,
    FooterComponent,
    MainmenuComponent,
    SidebarComponent,
    SubmenuComponent,
    FullScreenGalleryComponent,
    ThumbnailGalleryComponent,
    SplashComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule
  ],
  providers: [
      ContentService,
      SiteSettingsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
