import { Component, OnInit } from '@angular/core';

import { Category } from '../category';
import { SiteSettingsService } from '../site-settings.service';

@Component({
    selector: 'app-alstjohn-footer',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.css']
})

export class FooterComponent implements OnInit {
    socialLinks = [
        new SocialLink('icon-ptrst.gif', 'http://pinterest.com/alfuzzystjohn/'),
        new SocialLink('icon-tmb.gif', 'http://alstjohnofficial.tumblr.com/'),
        new SocialLink('icon-inst.gif', 'https://www.instagram.com/alstjohnofficial/'),
        new SocialLink('icon-fcbk.gif', 'https://www.facebook.com/AlStJohnOfficial/'),
        new SocialLink('icon-yt.gif', 'http://youtube.com/AlFuzzyStJohn/'),
        new SocialLink('icon-rss.gif', '?feed=rss2')
    ];
    menuItems: Category[];
    siteHeader: string;
    copyrightText = 'All photos and videos &copy;2018 Annichen Skåren - All rights reserved - The estate of Al St.John';

    constructor(private siteSettingsService: SiteSettingsService) {}

    ngOnInit() {
        this.siteSettingsService.getMainMenuItems()
            .then(items => this.menuItems = items);
        this.siteSettingsService.getSiteHeader()
            .then(header => this.siteHeader = header);
    }
}

export class SocialLink {
    private static imageDir = 'assets/images/';

    constructor(private filename: string, private linkUrl: string) {}

    getLinkUrl(): string {
        return this.linkUrl;
    }

    getIconUrl(): string {
        return SocialLink.imageDir + this.filename;
    }
}
