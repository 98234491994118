import { Component, OnDestroy, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router, NavigationEnd } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';

import { Category } from './category';
import { SiteSettingsService } from './site-settings.service';

declare let ga: Function;

@Component({
    selector: 'app-alstjohn',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.css'],
    animations: [
        trigger('mobileMenuAnimation', [
            state('inactive', style({
                width: '0',
                height: '0',
                right: '100vw',
                left: '96vw',
                opacity: '0'
            })),
            state('active', style({
                width: '100%',
                height: '100%',
                right: '100vw',
                left: '0',
                opacity: '0.9'
            })),
            transition('inactive => active', animate('400ms ease-in')),
            transition('active => inactive', animate('400ms ease-out'))
        ]),
        trigger('mobileMenuTextAnimation', [
            state('inactive', style({
                'font-size': '0',
                opacity: '0'
            })),
            state('active', style({
                'font-size': '5vh',
                opacity: '1'
            })),
            transition('inactive => active', animate('400ms ease-in')),
            transition('active => inactive', animate('400ms ease-out'))
        ])
    ]
})

export class AppComponent implements OnDestroy, OnInit {
    showMenu: boolean = false;
    menuState: string = 'inactive';
    siteHeader: string;
    categoryHeader: string;
    subscription: Subscription;
    mobileMenu: Category[];

    constructor(private siteSettingsService: SiteSettingsService,
                public router: Router) {
        this.subscription = siteSettingsService.currentCategory$.subscribe(
            category => {
                this.categoryHeader = category.title;
            }
        );
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                ga('set', 'page', event.urlAfterRedirects);
                ga('send', 'pageview');
            }
        });
    }

    toggleMenu(): void {
        this.showMenu = !this.showMenu;
        if (this.showMenu) {
            this.menuState = 'active';
        } else {
            this.menuState = 'inactive';
        }
    }

    ngOnInit() {
        this.siteSettingsService.getSiteHeader()
            .then(siteTitle => this.siteHeader = siteTitle);
        this.siteSettingsService.getMobileMainMenuItems()
            .then(items => this.mobileMenu = items);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
