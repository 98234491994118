import { Component, OnInit } from '@angular/core';

import { Category } from '../category';
import { GalleryImage } from '../gallery-image';

import { ContentService } from '../content.service';
import { SiteSettingsService } from '../site-settings.service';

@Component({
  selector: 'app-thumbnail-gallery',
  templateUrl: './thumbnail-gallery.component.html',
  styleUrls: ['./thumbnail-gallery.component.css']
})
export class ThumbnailGalleryComponent implements OnInit {
  subMenu: Category[];
  content: string;
  galleryImages: GalleryImage[];
  currentCategory: string = 'Gallery';

  constructor(
    private siteSettingsService: SiteSettingsService,
    private contentService: ContentService) { }

  ngOnInit() {
    let pageCategory = 'gallery';
    this.siteSettingsService.setCurrentCategory(pageCategory);
    this.siteSettingsService.getSubCategoryFor(pageCategory)
      .then(subCategory => this.subMenu = subCategory);
    /*this.contentService.getPageContent(pageCategory)
      .then(c => this.content = c);*/
    this.contentService.getGalleryImages()
      .then(i => this.galleryImages = i);
  }

}
