<div id="back-navigation-container-small">
    <a *ngIf="parentCategory" [routerLink]="parentCategory.routerLink" class="back-button"><h2>&lt;</h2></a>
    <h2 class="subheader">{{currentCategory}}</h2>
</div>

<div *ngIf="parentCategory" id="back-navigation-container">
    <a [routerLink]="parentCategory.routerLink" class="back-button"><h2>&lt;</h2></a>
    <h2 class="subheader">{{currentCategory}}</h2>
</div>

<nav>
    <ul>
        <li *ngFor="let menuItem of menuItems">
            <a routerLink="{{menuItem.routerLink}}">
                <div class="submenu-item">{{menuItem.title}}</div>
            </a>
        </li>
    </ul>
</nav>