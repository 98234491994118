<div id="category-header-container">
    <h2 id="site-header">{{siteTitle}}</h2>
    <h2 id="category-header">{{currentCategory}}</h2>
</div>

<div id="sidebar">
    <app-alstjohn-sidebar></app-alstjohn-sidebar>
</div>

<div id="content-container">
    <app-alstjohn-submenu [menuItems]="subMenu"></app-alstjohn-submenu>
    <div id="content" role="main" [innerHTML]="content">
</div>

</div>
